import "core-js/modules/es.array.push.js";
import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mapGetters } from "vuex";
import { mixins } from "@/plugins/mixins";
import bus from "@/plugins/bus";
export default {
  name: "userAdd",
  mixins: [mixins],
  computed: {
    ...mapGetters({
      isAdmin: 'perm/isAdmin'
    })
  },
  data() {
    return {
      orgList: [],
      edit: false,
      formModel: {
        userId: null,
        userCode: null,
        userName: null,
        userPwd: null,
        phone: null,
        validateDate: null,
        invalidateDate: null,
        remark: null,
        deptIds: [],
        dateRange: [],
        orgId: null
      },
      formRules: {
        orgId: [{
          required: true,
          message: '请选择所属机构',
          trigger: 'blur'
        }],
        userCode: [{
          required: true,
          message: '请输入用户编码',
          trigger: 'blur'
        }, {
          max: 16,
          message: '用户编码长度不能超过16',
          trigger: 'blur'
        }, {
          pattern: /^[a-zA-Z_0-9]+$/,
          message: '用户编码长度必须为字母、数字或_',
          trigger: 'blur'
        }],
        userName: [{
          required: true,
          message: '请输入用户名称',
          trigger: 'blur'
        }, {
          max: 32,
          message: '用户名称长度不能超过32',
          trigger: 'blur'
        }],
        userPwd: [{
          required: true,
          message: '请输入用户密码',
          trigger: 'blur'
        }, {
          max: 11,
          min: 8,
          message: '密码长度应为8-11',
          trigger: 'blur'
        }],
        phone: [{
          max: 14,
          message: '联系电话长度不能超过14',
          trigger: 'blur'
        }],
        remark: [{
          max: 64,
          message: '备注长度不能超过14',
          trigger: 'blur'
        }]
      },
      deptList: [],
      deptProps: {
        multiple: true,
        checkStrictly: true,
        emitPath: false,
        value: 'deptId',
        label: 'deptName'
      }
    };
  },
  methods: {
    submitForm() {
      this.$refs.formRef.validate(valid => {
        if (valid) {
          const loading = ElLoading.service();
          const param = Object.assign({}, this.formModel);
          console.log(this.formModel.dateRange);
          if (this.formModel.dateRange) {
            if (this.formModel.dateRange.length > 0) {
              param.validateDate = this.formModel.dateRange[0];
            }
            if (this.formModel.dateRange.length > 1) {
              param.invalidateDate = this.formModel.dateRange[1];
            }
            delete param.dateRange;
          } else {
            param.validateDate = null;
            param.invalidateDate = null;
            delete param.dateRange;
          }
          const callBack = res => {
            if (res.code === 200) {
              ElMessage({
                message: res.message,
                type: 'success'
              });
              this.$router.back();
              if (this.$route.meta && this.$route.meta.parent) {
                bus.emit(this.$route.meta.parent, true);
              }
            } else {
              ElMessage.error(res.message);
            }
          };
          if (this.edit) {
            this.$api.uac.user.update(param).then(callBack).finally(() => {
              loading.close();
            });
          } else {
            this.$api.uac.user.create(param).then(callBack).finally(() => {
              loading.close();
            });
          }
        }
      });
    }
  },
  beforeMount() {
    if (this.$route.query.userId) {
      this.edit = true;
      const loading = ElLoading.service();
      Promise.all([this.$api.uac.user.detail(this.$route.query.userId), this.$api.uac.dept.list()]).then(res => {
        if (res[0].code === 200) {
          this.formModel = Object.assign(this.formModel, res[0].data);
          this.formModel.dateRange = [this.formModel.validateDate, this.formModel.invalidateDate];
          if (res[0].data.deptList) {
            res[0].data.deptList.forEach(dept => this.formModel.deptIds.push(dept.deptId));
            delete this.formModel.deptList;
          }
          if (res[1].code === 200) {
            this.deptList = res[1].data;
          }
        } else if (res[0].code === 101102) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res[0].message);
          this.$router.back();
        }
      }).finally(() => {
        loading.close();
      });
    } else {
      this.$api.uac.dept.list().then(res => {
        if (res.code === 200) {
          this.deptList = res.data;
        }
      });
    }
    if (this.isAdmin) {
      this.$api.uac.org.list().then(res => {
        if (res.code === 200) {
          this.orgList = res.data;
        }
      });
    }
  }
};